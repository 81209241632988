import React,{Component} from 'react';
import { Link } from 'react-router-dom';
class Footer extends React.Component{
    render(){
        return(
            <div>
                           <footer>
                <div class="container">
                    <div class="row">

                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-4 col-xs-4">
                                    <div class="widget">
                                        <h5>Hosting</h5>
                                        <div class="tiny-border"></div>
                                        <ul>
                                            <li><Link to='/shared'>Shared Hosting</Link></li>
                                            <li><Link to='/reseller'>Reseller Hosting</Link></li>
                                            <li><Link to='/vps'>VPS Hosting</Link></li>
                                            <li><a href="https://portal.hostbd.us/cart.php?gid=2">WebSite Development</a></li>
                                            <li><a href="#">WordPress Hosting</a></li>
                                            <li><a href="#">Email Hosting</a></li>
                                            <li><a href="#">CMS Hosting</a></li>
                                            <li><a href="#">Ecommerce Hosting</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-4 col-xs-4">
                                    <div class="widget">
                                        <h5>Domains</h5>
                                        <div class="tiny-border"></div>
                                        <ul>
                                            <li><a href="https://portal.hostbd.us/cart.php?a=add&domain=register">Domain Checker</a></li>
                                            <li><a href="https://portal.hostbd.us/cart.php?a=add&domain=transfer">Domain Transfer</a></li>
                                            <li><a href="#">Free .COM Domain</a></li>
                                            <li><a href="#">Free XYZ Domain</a></li>
                                            <li><a href="#">Free SSL Certificate</a></li>
                                            <li><a href="https://portal.hostbd.us/cart.php?a=add&domain=register">Buy Domain Name</a></li>
                                            <li><a href="https://portal.hostbd.us/cart.php?a=add&domain=register">Whois Checker</a></li>
                                            <li><a href="#">Cheap SSL Certificate</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-4 col-xs-4">
                                    <div class="widget">
                                        <h5>Company</h5>
                                        <div class="tiny-border"></div>
                                        <ul>
                                            <li><Link to='/contact'>Contact</Link></li>
                                            <li><a href="#">Affiliates</a></li>
                                            <li><a href="#">Blog</a></li>
                                            <li><a href="#">Careers</a></li>
                                            <li><a href="#">Community</a></li>
                                            <li><a href="#">News</a></li>
                                            <li><a href="#">Partners</a></li>
                                            <li><a href="#">Press Releases</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="widget">
                                <a href="index.html"><img alt="" class="logo" src="assets/images/logo-light.png"/></a>
                                <div class="spacer-20"></div>
                                <p>We are Hostbd, a web hosting company with 24/7 customer support. We provide best hosting solutions for your hosting needs. Our clients from personal to corporate. Our data center are all over the world to ensure your website is always up. You can choose shared hosting, reseller hosting or VPS hosting. You can also be hosting reseller here. Happy hosting with us.</p>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-6 sm-text-center mb-sm-30">
                            <div class="mt10">&copy; Copyright 2018 - 2024 HostBD.Us All Rights Reserved.</div>
                        </div>

                        <div class="col-md-6 text-md-right text-sm-left">
                            <div class="social-icons">
                                <a href="https://www.facebook.com/hostbdus/"><i class="fa fa-facebook fa-lg"></i></a>
                                <a href="https://twitter.com/hostbdus/"><i class="fa fa-twitter fa-lg"></i></a>
                                <a href="#"><i class="fa fa-linkedin fa-lg"></i></a>
                                <a href="#"><i class="fa fa-google-plus fa-lg"></i></a>
                                <a href="#"><i class="fa fa-rss fa-lg"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </footer>
            
            </div>
        );
    }

}
export default Footer;